
import { Component, Prop, Vue } from 'vue-property-decorator';
import DoughnutChart from '@/lib/charts/doughnutChart';
import axiosClient from '@/lib/rest/axiosClient';
import { getConfigEnv } from '@/utils/helpers';
import GptMixin from '@/components/gpt/GptMixin';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    DoughnutChart,
  },
})
export default class GptOverviewCluster extends mixins(GptMixin) {
  @Prop({ type: Object, required: true }) tasq?: any;

  chartOptions = {
    responsive: true,
    borderRadius: 50,
    maintainAspectRatio: false,
    events: [],
    loaded: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 90,
    tooltips: {
      bodyFontSize: 7,
    },
  };

  commonIssues = [];

  hoveringOnTable = false;

  chartDataUpdated = 0;

  loadingCommonIssues = false;

  highestPercentageTitle = 'No data';

  chartData = {
    name: 'Tasqs',
    labels: ['Active', 'Completed', 'Yet to start'],
    datasets: [
      {
        name: 'Tasqs',
        labels: 'Tasqs',
        backgroundColor: ['#273e55', '#FFFFFF'],
        hoverBackgroundColor: ['#273e55', '#FFFFFF'],
        borderColor: ['#273e55', '#FFFFFF'],
        hoverBorderColor: '#FFFFFF',
        hoverBorderWidth: 1,
        borderAlign: 'outer',
        data: [13, 0],
        borderWidth: 2,
        borderRadius: 50,
      },
    ],
  };

  async mounted() {
    this.loadingCommonIssues = true;
    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );

    try {
      const {
        data: { most_common: mostCommon },
      }: any = await axiosClient.get('/analysis/well-common-issues', {
        params: {
          node_name: this.tasq.wellName,
          operator: operatorDetails['operatorName'],
          level:
            this.tasq && this.tasq.level
              ? this.tasq.level.toLowerCase()
              : 'well',
        },
      });

      const filteredData = mostCommon.filter((issue) => issue['sub category'] && issue['sub category'] !== 'Available') ;

      // Calculate the total count
      const totalCount = filteredData.reduce(
        (total, item) => total + item.count,
        0
      );

      // Calculate percentage and return modified filteredData
      let highestPercentage = 0;
      let highestPercentageTitle = '';

      // Calculate percentage, round to nearest whole number, and add "%" symbol
      const modifiedData = filteredData.map((item, index) => {
        const percentage = Math.round((item.count / totalCount) * 100);
        const cleanedTitle = item['sub category']
          .replace(/waiting on/i, '')
          .trim();

        if (percentage > highestPercentage) {
          highestPercentage = percentage;
          highestPercentageTitle = cleanedTitle;
        }

        return {
          id: index + 1,
          title: cleanedTitle,
          percentage: percentage + '%',
        };
      });

      if (!modifiedData.length) {
        this.loadingCommonIssues = false;
        return;
      }

      this.commonIssues = modifiedData;
      this.highestPercentageTitle = highestPercentageTitle;

      const remainingRank = 100 - highestPercentage;

      this.chartData['datasets'][0].data = [remainingRank, highestPercentage];
      console.log(this.chartData);
      this.chartDataUpdated += 1;
    } catch (error) {
      console.log(error);
    }
    this.loadingCommonIssues = false;
  }
}
